<template>
  <div class="twitter-navigation">
    <div class="twitter-navigation__wrapper">
      <div
        class="twitter-navigation__zoom-icon twitter-navigation__zoom-icon--left"
        style="display: flex;align-items: center;"
      >
        <div style="display: flex;margin-right: 2rem;color: #888;">
          <i @click="$emit('rotate', -90)" class="fas fa-undo pointer hoverHray" style="margin-right: 7px;"></i>
          <i @click="$emit('rotate', 90)" class="fas fa-redo pointer hoverHray"></i>
        </div>
        <i class="fas fa-image" style="margin-right: 1rem;"></i>
      </div>
      <div
        class="twitter-navigation__line-wrapper"
        ref="line"
        @mousedown="onStart"
        @touchstart="onStart"
      >
        <div class="twitter-navigation__line">
          <div
            class="twitter-navigation__fill"
            :style="{
              flexGrow: zoom,
            }"
          ></div>
          <div
            class="twitter-navigation__circle"
            :class="{ 'twitter-navigation__circle--focus': focus }"
            :style="{
              left: `${zoom * 100}%`,
            }"
          >
            <div
              class="twitter-navigation__inner-circle"
              :class="{ 'twitter-navigation__inner-circle--focus': focus }"
            ></div>
          </div>
        </div>
      </div>

      <div
        class="twitter-navigation__zoom-icon twitter-navigation__zoom-icon--right"
      >
        <i class="fas fas fa-image fa-2x"></i>
      </div>
    </div>
      <!-- <i @click="$emit('flip', true)" class="fas fa-arrows-alt-h"></i>
      <i @click="$emit('flip', false, true)" class="fas fa-arrows-alt-v"></i> -->
  </div>
</template>


<script>
export default {
  props: {
    zoom: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      focus: false,
    };
  },
  mounted() {
    window.addEventListener("mouseup", this.onStop, { passive: false });
    window.addEventListener("mousemove", this.onDrag, { passive: false });
    window.addEventListener("touchmove", this.onDrag, { passive: false });
    window.addEventListener("touchend", this.onStop, { passive: false });
  },
  destroyed() {
    window.removeEventListener("mouseup", this.onStop);
    window.removeEventListener("mousemove", this.onDrag);
    window.removeEventListener("touchmove", this.onDrag);
    window.removeEventListener("touchend", this.onStop);
  },
  methods: {
    onDrag(e) {
      if (this.focus) {
        const position = e.touches ? e.touches[0].clientX : e.clientX;
        const line = this.$refs.line;

        if (line) {
          const { left, width } = line.getBoundingClientRect();
          this.$emit(
            "change",
            Math.min(1, Math.max(0, position - left) / width)
          );
        }
        if (e.preventDefault) {
          e.preventDefault();
        }
      }
    },
    onStop() {
      this.focus = false;
    },
    onStart(e) {
      this.focus = true;
      // this.onDrag(e);
    },
  },
};
</script>

<style lang="scss">
.twitter-navigation {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 0.5rem;
  color: #39f;
  margin-bottom: 1rem;

  &__bottom {
    text-align: center;
    padding-top: 0.5rem;
    margin-top: 0.5rem;
    border-top: 1px solid #c3c3c3;

    i {
      cursor: pointer;
      width: 1.5rem;
    }
    i:not(:last-child) {
      margin-right: 0.75rem;
    }
  }
  &__wrapper {
    display: flex;
    align-items: center;
    max-width: 400px;
    width: 100%;
  }
  &__zoom-icon {
    fill: rgb(101, 119, 134);
    flex-shrink: 0;
    &--left {
      margin-right: 10px;
    }
    &--right {
      margin-left: 10px;
    }
  }
  &__line-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
  }
  &__line {
    background: rgb(142, 208, 249);
    height: 5px;
    width: 100%;
    border-radius: 5px;
    display: flex;
    position: relative;
    align-items: center;
  }
  &__fill {
    background: rgb(29, 161, 242);
    align-self: stretch;
    flex-basis: auto;
    flex-direction: column;
    flex-shrink: 0;
  }
  &__circle {
    width: 30px;
    height: 30px;
    margin-left: -15px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    transition-duration: 0.2s;
    transition-property: background-color, box-shadow;
    background-color: transparent;
    &:hover {
      background-color: rgba(29, 161, 242, 0.1);
    }
    &--focus {
      background-color: rgba(29, 161, 242, 0.2);
    }
  }
  &__inner-circle {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: rgb(29, 161, 242);
    transform: scale(1);
    transition-duration: 0.1s;
    transition-property: transform;
    box-shadow: rgba(101, 119, 134, 0.2) 0px 0px 7px,
      rgba(101, 119, 134, 0.15) 0px 1px 3px 1px;
    &--focus {
      transform: scale(1.2);
    }
  }
}
</style>
