<template>
  <div class="FileInput">
    <form ref="viewInput" action="">
      <input
        type="file"
        accept=".jpg,.jpeg,.png"
        :id="Id"
        @change="addPhoto"
        class="vInput"
      />
    </form>
    <div class="File_Panel" style="margin-top: 2rem">
      <div :class="{ File_hide: hide }" class="File_View">
        <Cropper
          :key="reloadKey"
          :src="currentPhoto || img"
          v-if="active"
          @change="changed"
        />
        <img
          v-else
          :src="img || require('@/assets/media/institution_placehold.png')"
          alt=""
        />
      </div>
      <div class="changeImg">
        <div class="changeImg_control">
          <label
            v-if="active"
            key="0"
            @click="savePhoto"
            class="select-image-btn btn btn-success"
          >
            <span><i class="fas fa-plus-circle"></i> Salvează poza</span>
          </label>
          <label :for="Id" key="1" :class="`select-image-btn btn btn-default`">
            <span
              ><i v-show="!active" class="fas fa-plus-circle"></i>
              {{ active ? "Schimbă poza" : "Adaugă poză" }}</span
            >
          </label>
          <template v-if="active">
            <label
              @click="cleanPhoto"
              :class="`select-image-btn btn btn-danger`"
            >
              <span><i class="fas fa-times-circle"></i></span>
            </label>
          </template>
        </div>
        <div class="changeImg_info">
          <span
            >După selectarea pozei dvs. puteți să o mutați, rotiți sau
            micșorați/măriți.</span
          >
          <span
            >Dimensiunea maximă este de 2Mb. Extensii permise: .jpg, .jpeg,
            .png</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<style src="@/assets/FileInput.css"></style>

<script>
import FileManager from "@/mixins/fileManager.js";
import { mapGetters } from "vuex";
import Cropper from "@/components/AvatarInput/cropper";

export default {
  name: "FileInput",
  mixins: [FileManager],
  props: {
    initialImage: {
      type: [String, Object, File, Blob],
      default: "",
    },
    blobMode: {
      type: Boolean,
      default: false,
    },
    showAvatar: {
      type: Boolean,
      default: false,
    },
    prepareBlobName: {
      type: Function,
      default: ()=>'File'
    },
    maxSize: {
      type: Number,
      default: 3145728
    }
  },
  components: {
    Cropper,
  },
  data() {
    return {
      id: `ID${this.makeid(9)}`,
      img: "",
      currentPhoto: "",
      hide: false,
      reloadKey: 0,
      croppedImage: "",
      currentFileData: null
    };
  },
  watch: {
    currentPhoto() {
      this.reloadKey++;
    },
    initialImage() {
      this.startInit()
    }
  },
  computed: {
    ...mapGetters(["USER_AVATAR"]),
    Id() {
      return this.loading ? `ID${this.makeid(9)}` : this.id;
    },
    active() {
      return !!this.currentPhoto;
    },
    isImg() {
      return this.active || !!this.img;
    },
  },
  methods: {
    up(x) {
      this.$emit("input", x);
    },
    async savePhoto() {
      this.$emit(
        "save",
        this.blobMode
          ? await this.dataURLtoFile(this.croppedImage, this.prepareBlobName(this.currentFileData))
          : this.croppedImage
      , this.croppedImage);
      
      this.saveCurrentImage()
    },
    saveCurrentImage() {
      this.hiderFn(() => {
        this.img = this.croppedImage
        this.currentPhoto = ""
      })
    },
    changed(img) {
      this.croppedImage = img?.canvas?.toDataURL() || "";

      this.$emit("cropped", this.croppedImage);
    },
    async addPhoto(e) {
      const file = e?.target?.files[0];
      if (!file || !file.type || !file.name) {
        return;
      }
      window.requestAnimationFrame(() => {
        this.$refs.viewInput?.reset();
      });

      const ext = file.name.split(".").pop();
      const permFileTypes = ["image/png", "image/jpg", "image/jpeg"];
      const permFileExtensions = ["png", "jpg", "jpeg"];

      if (
        !permFileTypes.includes(file.type) ||
        !permFileExtensions.includes(ext)
      ) {
        this.$toastr.w("Fișierul selectat nu conține o extensie permisă.");
        return;
      }
      if(this.maxSize < file.size) {
        this.$toastr.w("Mărimea maximă permisă a pozei nu trebuie să depășească 3MB.","Atentie!");
        return
      }

      this.currentFileData = file
      this.currentPhoto = await this.readAsDataUrl(file);
    },
    hiderFn(fn) {
      this.hide = true;
      setTimeout(() => {
        this.hide = false;
        if (typeof fn === "function") fn();
      }, 250);
    },
    cleanPhoto() {
      this.hiderFn(() => (this.currentPhoto = ""));
    },
    async getInitialImage() {
      const x = this.initialImage;
      if (this.isString(x)) return x;

      return this.readAsDataUrl(x);
    },
    async startInit() {
      this.img =
      (await this.getInitialImage()) ||
      (this.showAvatar ? this.getUserAvatar() : "");
    }
  },
  created() {
    this.startInit()
  },
};
</script>