<template>
  <div :style="cssSize.width">
    <div :style="cssSize.height">
      <cropper
        ref="cropper"
        image-restriction="stencil"
        :stencil-size="stencilSize"
        :stencil-props="{
          lines: {},
          handlers: {},
          movable: false,
          scalable: false,
          aspectRatio: 1,
          previewClass: 'twitter-cropper__stencil',
        }"
        :transitions="false"
        :canvas="true"
        :debounce="false"
        :default-size="defaultSize"
        :min-width="100"
        :min-height="100"
        :src="img"
        @change="onChange"
      />
    </div>
    <controls :zoom="zoom" @change="onZoom" @rotate="rotate" @flip="flip" @refresh="refresh" />
  </div>
</template>


<script>
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import Controls from "./controls";

export default {
  components: {
    Cropper,
    Controls,
  },
  props: {
    size: {
      type: [Number, Object],
      default: 200
    },
    src: {
      type: [String, Blob],
      default: null
    }
  },
  computed: {
    cssSize() {
      const { size } = this

      return {
        width: `width: ${size.width || size}px;`,
        height: `height: ${size.height || size}px;`
      }
    }
  },
  data() {
    return {
      zoom: 0,
      img: this.src,
    };
  },
  methods: {
    defaultSize({ imageSize }) {
      return {
        width: Math.max(imageSize.height, imageSize.width),
        height: Math.max(imageSize.height, imageSize.width),
      };
    },
    stencilSize() {
      return {
        width: this.size,
        height: this.size,
      };
    },
    onChange(result) {
      this.$emit('change', result)
      
      const cropper = this.$refs.cropper;
      if (cropper) {
        const { coordinates, imageSize } = cropper;
        if (
          imageSize.width / imageSize.height >
          coordinates.width / coordinates.height
        ) {
          // Determine the position of slider bullet
          // It's 0 if the stencil has the maximum size and it's 1 if the has the minimum size
          this.zoom =
            (cropper.imageSize.height - cropper.coordinates.height) /
            (cropper.imageSize.height - cropper.sizeRestrictions.minHeight);
        } else {
          this.zoom =
            (cropper.imageSize.width - cropper.coordinates.width) /
            (cropper.imageSize.width - cropper.sizeRestrictions.minWidth);
        }
      }
    },
    onZoom(value) {
      const cropper = this.$refs.cropper;
      if (cropper) {
        if (cropper.imageSize.height < cropper.imageSize.width) {
          const minHeight = cropper.sizeRestrictions.minHeight;
          const imageHeight = cropper.imageSize.height;
          // Determine the current absolute zoom and the new absolute zoom
          // to calculate the sought relative zoom value
          cropper.zoom(
            (imageHeight - this.zoom * (imageHeight - minHeight)) /
              (imageHeight - value * (imageHeight - minHeight))
          );
        } else {
          const minWidth = cropper.sizeRestrictions.minWidth;
          const imageWidth = cropper.imageSize.width;
          cropper.zoom(
            (imageWidth - this.zoom * (imageWidth - minWidth)) /
              (imageWidth - value * (imageWidth - minWidth))
          );
        }
      }
    },
    rotate(deg) {
      if(!Number.isInteger(deg)) return

      this.$refs.cropper?.rotate(deg)
    },
    flip(x, y) {
			if (this.$refs.cropper?.customImageTransforms.rotate % 180 !== 0) {
				this.$refs.cropper?.flip(!x, !y);
			} else {
				this.$refs.cropper?.flip(x, y);
			}
		},
    refresh() {
      this.$refs.cropper?.refresh();
      this.$refs.cropper?.reset();
      this.$refs.cropper?.resetCoordinates();
      this.$refs.cropper?.resetVisibleArea();
      console.dir(this.$refs.cropper)
    }
  },
};
</script>