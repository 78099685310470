var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:(_vm.cssSize.width)},[_c('div',{style:(_vm.cssSize.height)},[_c('cropper',{ref:"cropper",attrs:{"image-restriction":"stencil","stencil-size":_vm.stencilSize,"stencil-props":{
        lines: {},
        handlers: {},
        movable: false,
        scalable: false,
        aspectRatio: 1,
        previewClass: 'twitter-cropper__stencil',
      },"transitions":false,"canvas":true,"debounce":false,"default-size":_vm.defaultSize,"min-width":100,"min-height":100,"src":_vm.img},on:{"change":_vm.onChange}})],1),_c('controls',{attrs:{"zoom":_vm.zoom},on:{"change":_vm.onZoom,"rotate":_vm.rotate,"flip":_vm.flip,"refresh":_vm.refresh}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }